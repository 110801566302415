@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root{
    --primary: #C13333;
    --contrast: #ffc7be;
    --secondary: white;
    --highlight: #FFC053;
    --background: #F3F3F3;
    --font: #535250;
    --react: #00D4FF;
    --express: #20B800;
    --green: #1FA83A;

}

button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}


/* ----- DESKTOP ----- */

body {
    background-color: var(--background);
}

h2 {
    font-size: 6rem;
    margin: 3rem 15%;
    color: var(--font);
    font-weight: 500;
}

/* - PSEUDO CLASSES - */

.hidden {
    visibility: hidden;
}

.not-displayed {
    display: none;
}


/* --- SECTIONS --- */
/* -- LANDING -- */

.landing {
    height: 100vh;
    /* min-height: 100vh; */
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;           /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    max-height: -moz-available;
    max-height: -webkit-fill-available;
    max-height: fill-available;
    /* max-height: 100vh; */
    background-color: var(--secondary);
    
}

.landing-main {
    padding: 5rem 10rem;
    display: flex;
    height: 87%;
    
    background-color: var(--primary);
    border-radius: 0 0 5rem 5rem;
    color: white;
    overflow: hidden;
    z-index: 10;
}

.landing-main div {
    margin-left: 6rem;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-main h1 {
    font-family: 'Open Sans', sans-serif;
    line-height: min(8vw, 17vh);
    font-size: min(8vw, 17vh);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.landing-main h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 2rem 0;
    color: var(--highlight);
}

.landing-main h3::before {
    content: "> ";
}

.landing-main p {
    font-size: 2em;
}

.landing-main img {
    max-width: 30%;
    height: 100%;
    user-select: none;
}

.landing-banner {
    position: relative; /* DELETE HERE IF TROUBLE WITH STRIP POSITION and CHANGE SEE-WORK-CONTAINER */
    height: 13%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 6rem;
    padding-bottom: 1.2rem;
}


.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-50%);
}

.controls img {
    max-height: 2rem;
    height: 2rem;
    user-select: none;
}

.theme-btn {
    position: relative;
    width: 5.7rem;
    height: 2.6rem;
    /* padding: 0 0.1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.3rem;
    background-color: var(--highlight);
}

.theme-btn img {
    padding: 0.1rem;
}

.theme-logo {
    padding: 0.4rem;
    height: 100%;
    fill: white;
    z-index: 5;
    transition: all 0.5s ease-in-out;
}

.theme-indicator {
    box-sizing: content-box;
    position: absolute;
    height: 100%;
    aspect-ratio: 1;
    background-color: white;
    /* box-shadow: 0 0 0 5px var(--background); */
    border-radius: 50%;
    z-index: 4;
    transform: translateX(120%) scale(1.01);
    transition: all 0.3s ease-in-out;
}

.theme-logo.theme-selected {
    fill: var(--highlight);
}

.theme-indicator.theme-selected {
    transform:  translateX(0) scale(1.01);
}

.language-btn {
    position: relative;
    margin-top: 2rem;
    display: flex;
    flex-wrap: nowrap;
}

.language-btn img {
    margin: 0.4rem;
    height: 2.6rem;
    width: 40%;
    border: 1px solid black;
}

.lang-indicator {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    border: 2px solid var(--font);
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
}

.en-selected {
    transform: translateX(100%);
}

.see-work-container {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: calc(100% + 2rem);
    min-height: 110%;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.see-work-container .strip-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.hovering {
    animation: 0.7s hover infinite alternate ease-in-out;
}
@keyframes hover {
    from{transform: translateY(0);}
    to{transform: translateY(-1rem);}
}


/* .see-work-container .circle {
    position: absolute;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-color: var(--primary);
    border-radius: 50%;
    bottom: 0;
    z-index: 1;
} */

.see-work-container .strip {
    position: absolute;
    margin: 0 auto;
    width: 60%;
    left: 0;
    right: 0;
    bottom: 0;
    fill:var(--primary);
    fill-opacity: 1;
    z-index: 1;
    /* stroke:  black;
    stroke-width: 3px; */
}



.see-work-container .strip-container p {
    position: relative;
    color: white;
    white-space: nowrap;
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    z-index: 2;
    user-select: none;
}

.caret-down {
    position: relative;
    fill: white;
    width: 10%;
    margin-bottom: 1rem;
    min-width: 2rem;
    z-index: 2;
}

.linkedin-btn {
    align-self: center;
    color: var(--font);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    user-select: none;
}

.linkedin-btn p {
    display: block;
    white-space: nowrap;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 2vw;
}

.linkedin-btn .linkedin-logo {
    fill: var(--font);
    height: 66%;
    margin-left: 2rem;
}


/* -- PROJECTS -- */

.project h3 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: calc(-3rem + 7vw);
}

.project p {
    font-size: 1.8rem ;
}

.project .presentation {
    display: flex;
    width: 100%;
    color: var(--font);
    background-color: var(--secondary);
    padding: 3rem 15%;
    padding-bottom: 8rem ;
}

.project .design {
    width: 120%;
    display: flex;
    margin-left: 2rem
}

.project .tech-logos {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    margin: 0 1rem;
}

.project .tech-logos .react-tech {
    color: var(--react);
}

.project .tech-logos .react-tech svg {
    fill: var(--react);
}


.project .tech-logos .express-tech {
    color: var(--express);
    margin-top: 2rem;
}

.project .tech-logos .express-tech * {
    fill: var(--express);
}


.project .tech-logos p {
    font-size: 1.2rem;
}

.project svg {
    width: 3rem;
}

.project .illustration{
    display: flex;
    align-items: center;
}

.project img {
    width: 100%;
    min-width: 350px;
    border-radius: 0.5rem;
}

.project .details {
    margin: 0 15%;
    position: relative;
}

.project .details-banner {
    position: relative;
    background-color: var(--primary);
    border-radius: 1rem;
    transform: translateY(-6rem);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow: hidden;
    color: white;
    padding: 0 10%;
    z-index: 2;
    /* box-shadow: 0 0px 0px 2px var(--primary); */
    border-bottom: 7px solid var(--contrast);
    /* border-left: 5px dotted var(--contrast); */
    /* border-top: 5px solid var(--contrast); */
    
}

.project .detail-link {
    margin: 1.8rem 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.project .detail-link * {
    fill: white;
}

.project .detail-button {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    height: fit-content;
    margin-top: auto;
    cursor: pointer;
    user-select: none;
}

.project .detail-button svg {
    width: 100%;
    max-width: 500px;
    /* height: max-content; */
    transform: translateY(10%);
    stroke-width: 5px;
    stroke: var(--contrast);
}

.project .detail-button p {
    position: absolute;
    font-size: 2.5rem;
    margin-bottom: min(0.5vw, 0.7rem);
}

.project .drop-down {
    position: relative;
    height: 0;
    min-height: 0;
    width: 100%;
    transform: scaleY(1) translateY(-7rem);
    background-color: var(--secondary);
    border-radius: 0 0 1rem 1rem;
    border: 5px solid var(--primary);
    overflow: hidden;
    transition: min-height 0.7s ease-in-out;
}

.project .drop-down-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 3rem 10% 3rem 10%;
    display: flex;
    justify-content: space-between;
    list-style: inside;
}

.project .drop-down-content ul {
    margin: 0 0.5rem;
    color: var(--font);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 1.2rem;
}

.project .drop-down-content ul h4 {
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
    font-weight: 500;
}

.project .drop-down-content ul li {
    line-height: 1.8rem;
}

.project .drop-down.opened {
    /* transform: scaleY(0) translateY(-7rem); */
    min-height: fit-content;
    transition: min-height 0.7s ease-in-out ;
}


.side-projects {
    padding: 3rem 15%;
    background-color: var(--secondary);
    display: flex;
    height: 27rem;
    margin-bottom: 7rem;
}

.side-projects .infos {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 1.8rem;
    color: var(--font);
    margin-right: 3rem;

}


.side-projects .infos .catch-phrase {
    text-align: right;
}


/* -- ABOUT -- */

.about-content {
    background-color: var(--secondary);
    padding: 7rem 15%;
    display: flex;
}

.about-content .infos p {
    font-size: 1.8rem;
    color: var(--font);
    margin-bottom: 1.5rem;
}

.about-content .infos {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.about-content .formation-logos {
    flex-grow: 3;
    display: flex;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    user-select: none;
}


.formation-logos .logo-container img{
    height: 4vw;
}

.about-resume {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-left: 10%;
}

.about-resume img {
    width: 100%;
    object-fit: contain;
    border: 0.5rem solid var(--background);
}

.about-resume a {
    display: table-cell;
    width: 100%;
    /* height: 4rem; */
    padding: 1rem 0;
    background-color: var(--background);
    border-radius: 2rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    text-align: center;
    color: var(--font);
    user-select: none;
}

/* -- CONTACT -- */

.form-container {
    padding: 7rem 15%;
    background-color: var(--secondary);
}

.contact-form {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8rem;
}

.contact-form label {
    margin: 0 auto 0 2rem;
    font-size: 1.8rem;
    color: var(--font);
}

.contact-form .input {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3rem;
    outline: none;
    background-color: var(--secondary);
    color: var(--font);
}

.contact-form .input::placeholder{
    font-style: italic;
}

.contact-form .input:focus {
    border-bottom:2px solid var(--react);
    box-shadow: 0 1px 0 var(--react) ;
    /* background-color: rgb(246, 246, 246); */
}


.contact-form .email-input{
    width: 80%;
    min-width: min(35rem, calc(100% - 4rem));
    align-self: flex-start;
    margin-left: 2rem;
}

.contact-form .name-input{
    width: 50%;
    min-width: min(25rem, calc(100% - 4rem));
    align-self: flex-start;
    margin-left: 2rem;
}

.contact-form .message-container .subject-input {
    margin-bottom: 1rem;
    padding: 0 1rem 0.5rem 1rem;
    border-bottom: 2px solid var(--font);
    width: 100%;
}

.contact-form .message-container .subject-input:focus {
    border-bottom:2px solid var(--react);
    box-shadow: 0 1px 0 var(--react) ;
}

.contact-form .message-container .message-area {
    width: 100%;
    height: 15rem;
    border-radius: 1.1rem;
    padding: 1rem;
}

.contact-form .message-container .message-area:focus {
    border:3px solid var(--react);
}

.contact-form button {
    background-color: var(--background);
    /* background-color: var(--green); */
    font-size: 1.8rem;
    color: var(--font);
    padding: 0 2rem;
    height: 4rem;
    border-radius: 2rem;
}

.contact-form > input[type="text"] {
    border: 0;
    border-bottom:2px solid var(--font);
}

.contact-form > input[type="email"] {
    border: 0;
    border-bottom:2px solid var(--font);
}

.contact-form .message-container {
    border: 2px solid var(--font);
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
    background-color: var(--secondary);
}

.contact-form .message-container * {
    border: 0;
    resize: none;
    background-color: var(--secondary);
}


.contact-form .error-container {
    margin: 0 auto 1rem 2rem;
    color: red;
    font-size: 1rem;
}

.contact-form .display-container {
    margin-bottom: 0.5rem;
    color: var(--green);
    font-size: 1.2rem;
}

.contact-form .confirmation-logo {
    width: 4rem;
}

.contact-form .confirmation-logo svg {
    fill: var(--green);
}

.contact-form .loading-logo {
    width: 4rem;
    height: 4rem;
    animation: spin 1s linear infinite ;
}


@keyframes spin {
    from {transform: rotate(0);}
    to {transform: rotate(360deg);}
}

.contact-form .error-logo {
    width: 4rem;
}

.contact-form .error-logo svg {
    fill: red;
}

.red p {
    color: red;
}



/* --- COMPONENTS --- */

/* -- NAVBAR -- */

.navbar-container{
    /* display: none; */
    position: sticky;
    height: 5rem;
    overflow: visible;
    top: 0;
}

.navbar {
    height: 100vh;
    width: 10%;
    border-right: 1px solid var(--font);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
}


.navbar-logo {
    fill: var(--font);
    margin-top: 15%;
    height: 3rem;
    max-width: 70%;
    transition: all 0.3s ease-in-out;
}

.navbar h4 {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 600;
    color: var(--font);
    transform: translateY(0);
    opacity: 1;
    transition: all 0.7s;
}

.navbar h4.nav-hidden{
    opacity: 0;
    transform: translateY(-0.5rem);
    transition: all 0.7s;
}

.react-logo {
    stroke: var(--font);
    stroke-width: 5px;
    scale: 1.2;
    transition: all 0.3s ease-in-out;
}

.navbar button:hover h4{
    /* color: rgb(255, 188, 19); */
    color: var(--font);
}


.navbar button:hover svg{
    fill: var(--highlight);
    stroke: var(--highlight);
    /* background: radial-gradient(circle at center, rgb(255, 231, 166) 0,rgba(0,0,0,0) 50%); */
    transition: all 0.3s ease-in-out;
}

/* -- SNAKE CARD -- */

.snake-card {
    background-color: var(--primary);
    border-radius: 1rem;
    text-align: center;
    min-width: 25rem;
    /* height: 140%; */
    height: fit-content;
    padding: 1.5rem 3rem;
    color: white;
    border-bottom: 7px solid var(--contrast);
}

.snake-card h4 {
    font-size: 2rem;
    font-weight: 500;
}

.snake-card .snake-illustration {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
}

.snake-card .snake-illustration img {
    display: block;
    border-radius: 50%;
    width: 100%;
    padding: 5rem;
}

.snake-card .snake-illustration .circle {
    position: absolute;
    box-sizing: border-box;
    border: 0.4rem solid white ;
    width: 65%;
    aspect-ratio: 1;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%) scale(1);
    animation: pulse 1s infinite ease-in-out alternate;
    cursor: pointer;
    /* -moz-animation: pulse 1.5s ease-in-out infinite alternate; */
}

@keyframes pulse {
    from {transform: translate(-50%,-50%) scale(1) }
    to {transform: translate(-50%,-50%) scale(1.1)}
}

.snake-card .github-link {
    display: block;
    width: fit-content;
    margin-left: auto;
    transform: translateY(-3rem);
}

.snake-card .github-link svg {
    width: 3rem;
}

.snake-card .github-link svg *{
    fill: white;
}

.snake-card > p {
    text-align: left;
    font-size: 1.2rem;
}


/* ----- TABLET ----- */

/* @media screen and (max-width:1200px), (max-height: 750px) {


    /* --- LANDING ---
    .landing-main {
        position: relative;
        padding: 7rem 10%;
    }

    .landing-main img{
        position: absolute;
        top: 0;
        right: 0;
        height: min(22vw,34vh);
        margin: 7rem;
    }

    .landing-main div {
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
    }

    .landing-main h3 {
        font-size: 1.8rem;
        margin-bottom: max(10vh,7vw);
    }

    .landing-main p {
        font-size: 1.5rem;
    }

    .navbar {
        display: none;
    }
} */


/* ----- TABLET ----- */

@media screen and (max-width: 1200px) {

    h2 {
        font-size: 4rem;
        margin: 3rem 5rem;
    }

    /* --- LANDING --- */
    /* .landing-main {
        padding: 2rem;
        border-radius: 0 0 3rem 3rem;
    }

    .landing-main div {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: 0 3rem;
    }

    .landing-main p {
        font-size: 1.8rem;
    } */

    .landing-main {
        padding: 2rem;
        border-radius: 0 0 3rem 3rem;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .landing-main div {
        margin: 0;
    }

    .landing-main>div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .landing-main p {
        font-size: 1.8rem;
        margin: 3rem 0;
    }

    .landing-banner {
        padding: 0 3rem;
    }

    .see-work-container {
        width: 50%;
    }

    .see-work-container .strip-container p{
        font-size: 1.5rem;
    }

    .linkedin-btn {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .linkedin-btn p {
        font-size: 1.2rem;
    }

    .linkedin-btn .linkedin-logo {
        margin: 0;
        width: 3rem;
    }

    /* --- PROJECTS --- */
    .project h3 {
        margin-bottom: 2.5rem;
    }
    .project .presentation {
        padding: 3rem 5rem;
        padding-bottom: 7rem;
    }

    .project .presentation .infos>p {
        margin: 1rem 0;
        text-align: center;
    }

    .project .design {
        width: 100%;
        margin: 0;
    }

    .project img {
        width: 80%;
        min-width: 0;
    }

    .project .details {
        margin: 0 1rem;
    }

    .project .details-banner {
        /* border-radius: 2rem; */
        padding: 0 5%;
    }

    .project .detail-button {
        justify-content: center;
    }

    .project .detail-button p {
        margin-top: 1rem;
    }

    .project .drop-down {
        width: calc(100%);
        margin: 0 auto;
    }

    .side-projects {
        flex-flow: column nowrap;
        height: 35rem;
        padding: 5rem 5rem;
    }

    .side-projects .infos {
        font-size: 1.5rem;
        margin: 0;
        margin-bottom: 3rem;
    }

    .snake-infos {
        display: flex;
        justify-content: flex-end;
    }

    .catch-phrase {
        color: var(--font);
        font-size: 1.5rem;
        align-self: center;
        text-align: end;
        margin-right: 1rem;
    }

    .snake-card {
        width: 20rem;
        min-width: 0;
    }

    .snake-card .snake-illustration img {
        padding: 26%;
    }

    /* --- ABOUT ---*/

    .about-content {
        display: block;
        padding: 5rem 5rem;
    }

    .about-content .infos p {
        font-size: 1.5rem;
    }

    .about-content .infos {
        margin-bottom: 3rem;
    }

    .resume-and-logos {
        display: flex;
        
    }

    .about-content .formation-logos {
        flex-flow: column nowrap;
        gap: 4rem;
        margin: 0;
    }

    .formation-logos .logo-container img {
        /* height: 3.5rem; */
        height: 7vw;
    }

    /* --- CONTACT --- */

    .contact-form {
        width: 100%;
    }


    /* ---- COMPONENTS ---- */

    .navbar {
        display: none;
    }

    /* -- BURGER -- */

    .burger {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
    }

    .click-catcher {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color:  rgba(0, 0, 0, 0.3);
    }

    .burger-icon {
        margin: 2rem;
        margin-bottom: 1rem;
        width: 6rem;
        height: 6rem;
        background-color: var(--secondary);
        border: 2px solid var(--font);
        border-radius: 1.2rem;
        padding: 1.5rem 1.2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        z-index: 5;
    }


    .line {
        background-color: var(--highlight);
        border: 0.2rem solid var(--highlight);
        border-radius: 0.2rem;
        transition: all 0.2s ease-in-out;
        width: 100%;
        margin-left: auto;
    }

    .line-1.cross {
        transform: translateX(-0.6rem) rotate(-45deg);
        transform-origin: 100%;
    }

    .line-2.cross {
        width: 2px;
    }

    .line-3.cross {
        transform: translateX(-0.6rem) rotate(45deg);
        transform-origin: 100%;
    }


    .burger-menu {
        margin-right: 2rem;
        width: 8rem;
        height: 30rem;
        background-color: var(--secondary);
        border: 2px solid var(--font);
        border-radius: 1.2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
        transition: all 0.2s ease-in-out;
    }

    .burger-menu.closed{
        opacity: 0;
        transform: translateX(2rem);
        visibility: hidden;
    }

    .burger-menu-button {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .burger-menu svg {
        width: 3rem;
        fill: var(--font);
    }
}

/* ----- MOBILE ----- */

/* @media screen and (max-width: 700px), screen and (max-width:1200px) and (orientation:landscape) { */
@media screen and (max-width: 700px) {

    h2 {
        font-size: 2rem;
        margin: 3rem 1rem;
        margin-bottom: 1rem;
    }

    /* --- LANDING --- */

    .landing-main {
        border-radius: 0 0 2rem 2rem;
    }

    .landing-main h3 {
        font-size: 1.5rem;
    }

    .landing-main p {
        font-size: 1.2rem;
        margin: 1rem 0;
    }

    .landing-banner {
        padding: 0 1rem;
    }

    .theme-btn {
        width: 4rem;
        height: 2rem;
        align-self: flex-start;
    }

    .theme-indicator {
        transform: translateX(100%) scale(1.01);
    }

    .language-btn img {
        width: 2rem;
        height: 1.4rem;
    }

    .see-work-container .strip-container p {
        font-size: 1rem;
    }

    .linkedin-btn p {
        display: none;
    }

    /* --- PROJECTS --- */

    .project .presentation {
        padding: 3rem 1rem;
    }

    .project h3 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .project p {
        font-size: 1rem;
    }

    .project .design {
        flex-direction: column-reverse;
    }

    .project .illustration {
        justify-content: center;
        margin-bottom: 1rem;
    }

    .project img {
        width: 90%;
    }

    .project .tech-logos {
        flex-direction: row;
        justify-content: center;
        gap: 20%;
    }

    .project .tech-logos .express-tech {
        margin: 0;
    }

    .project .tech-logos svg {
        width: 2rem;
    }

    .project .tech-logos p {
        font-size: 1rem;
    }

    .project .presentation .infos > p {
        font-size: 1rem;
    }

    .project svg {
        width: 1.8rem;
    }

    .project .detail-button {
        width: 50%;
    }

    .project .detail-link {
        margin: 1rem 0;
    }

    .project .detail-button p {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .project .details-banner {
        transform: translateY(-3rem);
    }

    .project .drop-down-content ul h4 {
        font-size: 1rem;
    }

    .project .drop-down-content ul li {
        font-size: 0.8rem;
        line-height: normal;
    }

    .project .drop-down-content {
        flex-direction: column;
        gap: 1rem;
        padding-top: 6rem;
    }

    .side-projects {
        padding: 3rem 1rem;
        height: auto;
        padding-bottom: 1rem;
    }

    .side-projects .infos {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .catch-phrase {
        font-size: 1rem;
        margin: 0;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: 90%;
        max-width: 350px;
    }

    .snake-infos {
        flex-direction: column;
    }

    .snake-card {
        width: 90%;
        max-width: 350px;
        margin: 0 auto;
        padding: 1.5rem;
    }

    .snake-card h4 {
        font-size: 1.8rem;
    }

    .snake-card > p {
        font-size: 1rem;
    }

    /* --- ABOUT --- */

    .about-content {
        padding: 3rem 1rem;
    }

    .about-content .infos {
        margin-bottom: 0;
    }

    .about-content .infos p {
        font-size: 1rem;
    }

    .resume-and-logos {
        flex-direction: column;
    }

    .about-content .formation-logos {
        flex-flow: row nowrap;
        gap: initial;
        justify-content: space-evenly;
        margin: 1rem 0;
    }

    .formation-logos .logo-container img {
        min-height: 2rem;
    }

    .about-resume {
        margin: 0 auto;
        width: 90%;
        max-width: 350px;
    }

    /* --- CONTACT --- */ 

    .form-container {
        padding: 3rem 1rem;
    }

    .contact-form label {
        font-size: 1.2rem;
    }

    .contact-form .input {
        font-size: 1rem;
    }

    .contact-form .error-container {
        margin: 0 auto 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .contact-form .display-container {
        font-size: 1rem;
    }


    /* ---- COMPONENTS ---- */

    .navbar-container {
        height: 0;
    }

    /* --- BURGER --- */

    .burger-icon {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        padding: 1rem 0.8rem;
        margin: 1rem;
        margin-bottom: 0.5rem;
    }

    .line {
        border: 0.1rem solid var(--highlight);
    }

    .line-1.cross {
        transform: translateX(-0.3rem) rotate(-45deg);
    }

    .line-3.cross {
        transform: translateX(-0.3rem) rotate(45deg);
    }

    .burger-menu {
        margin-right: 1rem;
        width: 5rem;
        height: 15rem;
    }

    .burger-menu svg {
        width: 2rem;
    }

}

@media screen and (max-height: 700px) and (max-width: 1200px) and (orientation: landscape){
    .burger-icon {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        padding: 1rem 0.8rem;
        margin: 1rem;
        margin-bottom: 0.5rem;
    }

    .line {
        border: 0.1rem solid var(--highlight);
    }

    .line-1.cross {
        transform: translateX(-0.3rem) rotate(-45deg);
    }

    .line-3.cross {
        transform: translateX(-0.3rem) rotate(45deg);
    }

    .burger-menu {
        margin-right: 1rem;
        width: 5rem;
        height: 12rem;
    }

    .burger-menu svg {
        width: 1.5rem;
    }
}


